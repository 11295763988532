@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

body,html{
  padding:0;
  margin:0;
  background-image: url('../public/koncert.jpg');
  background-size: cover;
  background-color: rgba(0,0,0,0.8);
  background-blend-mode: overlay;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
}